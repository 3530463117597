<template>
  <div class="delivery-instruction-area px-2 pb-2">
    <div class="d-flex justify-space-between">
      <ion-label class="label pt-2"
        >{{
          idModal === 'viewOrder' ? $t('order_delivery_instruction') : $t('customer_instruction')
        }}:</ion-label
      >
      <ion-button
        v-if="
          (idModal !== 'view' && idModal !== 'viewOrder' && !isPaid) ||
          ((idModal === 'view' || idModal === 'viewOrder') && !isInstructionEmpty)
        "
        id="open-add-edit-modal"
        class="add-edit-btn"
        size="small"
        fill="clear"
        color="primary"
        @click="setOpenModalAddEditInstruction(true)"
      >
        <ion-icon
          v-if="idModal === 'addAndEdit'"
          slot="start"
          :icon="isInstructionEmpty ? add : pencil"
        ></ion-icon>
        {{
          idModal === 'view' || idModal === 'viewOrder'
            ? $t('view')
            : isInstructionEmpty
            ? $t('add')
            : $t('edit')
        }}
      </ion-button>
      <ion-modal
        ref="modal"
        trigger="open-add-edit-modal"
        :initial-breakpoint="1"
        :breakpoints="[0, 0.7, 0.5, 1]"
        :is-open="isOpenModalAddEditInstruction"
        mode="ios"
        css-class="modal-instruction"
        @didDismiss="setOpenModalAddEditInstruction(false)"
      >
        <AddAndEditInstructionModal
          @closeModal="instructionModalClosed"
          :instruction="userInstruction"
          :idModal="idModal"
        />
      </ion-modal>
    </div>
    <div :class="!isInstructionEmpty ? 'mt-1 truncate-text instruction-text' : 'mt-1 instruction-text'">
      {{
        (isInstructionEmpty && (idModal === 'view' || idModal === 'viewOrder')) ||
        (idModal !== 'view' && idModal !== 'viewOrder' && isPaid && isInstructionEmpty)
          ? '-'
          : isInstructionEmpty && idModal !== 'view' && idModal !== 'viewOrder'
          ? $t('delivery_instruction_hint')
          : userInstruction
      }}
    </div>
  </div>
</template>
<script>
import { add, pencil } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import AddAndEditInstructionModal from './AddAndEditInstructionModal.vue';

export default defineComponent({
  components: {
    AddAndEditInstructionModal
  },
  props: {
    instruction: {
      type: String,
      default: ''
    },
    idModal: {
      type: String,
      default: ''
    },
    isPaid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isInstructionEmpty() {
      return this.userInstruction === '' || this.userInstruction === null;
    }
  },
  setup() {
    const isOpenModalAddEditInstruction = ref(false);
    const setOpenModalAddEditInstruction = async (state) => (isOpenModalAddEditInstruction.value = state);
    return {
      add,
      pencil,
      isOpenModalAddEditInstruction,
      setOpenModalAddEditInstruction
    };
  },
  data() {
    return {
      userInstruction: this.instruction
    };
  },
  methods: {
    instructionModalClosed(instructionVal) {
      this.setOpenModalAddEditInstruction(false);
      this.userInstruction = instructionVal;
      this.$emit('deliveryInstruction', this.userInstruction);
    }
  }
});
</script>

<style src="./style.scss" lang="scss" scoped></style>
